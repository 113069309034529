import React, { useState, useEffect } from "react";
import axios from "axios";
import ScrollAnimation from "react-animate-on-scroll";
import Header from "../components/layouts/Header";
import errors from "../errors.json";

function ResetPassword(props) {
  const { title, emailAddress, token } = props.match.params;

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormdata] = useState({
    email: emailAddress,
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {}, [title]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const submitHandler = async (event) => {
    setIsLoading(true);
    setError(false);
    event.preventDefault();
    if (!formData.email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formData.password) {
      setError(true);
      setMessage("Password is required");
    } else if (!formData.confirmPassword) {
      setError(true);
      setMessage("Confirm password is required");
    } else if (formData.password.length < 6) {
      setError(true);
      setMessage("Password must be at least six characters long");
    } else if (formData.password !== formData.confirmPassword) {
      setError(true);
      setMessage("Password and confirm password doesn't match");
    } else {
      setError(false);
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_TOMBOLA_ONLINE_BASE_URL}/users/reset-password/${emailAddress}`,
          {
            password: formData.password,
            token,
          }
        );
        setMessage("Password resetted!");
      } catch (error) {
        console.log(error.response.data.message);
        setError(true);
        setMessage(errors[error.response.data.message]);
      }
    }
    setIsLoading(false);
  };

  const handleChange = (event) => {
    setFormdata({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  document.body.classList.add("dark");

  return (
    <>
      <Header
        logoSource="/images/logo.png"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            {title === "tombola-online" ? (
              <div className="blog-single shadow-dark p-30">
                <div className="row">
                  <div className="col-md-4">
                    <div className="contact-info">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <h3>Reset your password</h3>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      >
                        <p>App: {title}</p>
                      </ScrollAnimation>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <form
                      id="contact-form"
                      className="contact-form mt-6"
                      onSubmit={submitHandler}
                    >
                      <div className="row">
                        <div className="column col-md-12">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="InputEmail"
                              name="email"
                              disabled={true}
                              placeholder="Email address"
                              onChange={handleChange}
                              value={formData.email}
                            />
                          </div>
                        </div>

                        <div className="column col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="InputPassword"
                              name="password"
                              placeholder="New Password"
                              onChange={handleChange}
                              value={formData.password}
                            />
                          </div>
                        </div>

                        <div className="column col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="InputConfirmPassword"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              value={formData.confirmPassword}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        name="submit"
                        id="submit"
                        value="Submit"
                        className="btn btn-default"
                      >
                        {isLoading ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Reset Password"
                        )}
                      </button>
                    </form>
                    {handleAlerts()}
                  </div>
                </div>
              </div>
            ) : (
              <div className="blog-single shadow-dark p-30">
                <p>This page is not available for this app.</p>
              </div>
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default ResetPassword;
