import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import Header from "../components/layouts/Header";

function AppDetailsPrivacy(props) {
  const [content, setContent] = useState("");
  const { title: blogFile, language: paramsLang } = useParams();
  const language = !paramsLang ? "en" : paramsLang;

  useEffect(() => {
    axios
      .get(require(`../apps/privacy/${blogFile}-${language}.md`))
      .then((result) => {
        setContent(result.data);
      });
  }, [content, blogFile]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  document.body.classList.add("dark");

  return (
    <>
      <Header
        logoSource="/images/logo.png"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
              <ReactMarkdown children={content}></ReactMarkdown>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default AppDetailsPrivacy;
