import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import AppList from "./pages/AppList";
import AppDetails from "./pages/AppDetails";
import ResetPassword from "./pages/ResetPassword";
import "./App.scss";
import AppDetailsPrivacy from "./pages/AppDetailsPrivacy";
import AppDetailsTermsAndConditions from "./pages/AppDetailsTermsAndConditions";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact Component={Homepage} />
        <Route path="/apps" Component={AppList} exact />
        <Route
          path="/apps/app-details/:title/reset-password/:emailAddress/:token"
          Component={ResetPassword}
        />
        <Route
          path="/apps/app-details/:title/privacy/:language"
          Component={AppDetailsPrivacy}
        />
        <Route
          path="/apps/app-details/:title/privacy"
          Component={AppDetailsPrivacy}
        />
        <Route
          path="/apps/app-details/:title/terms-and-conditions/:language"
          Component={AppDetailsTermsAndConditions}
        />
        <Route
          path="/apps/app-details/:title/terms-and-conditions"
          Component={AppDetailsTermsAndConditions}
        />
        <Route path="/apps/app-details/:title" Component={AppDetails} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
