import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/avatar-1.svg",
    userName: "AnPanAnke",
    subtitle: "Tombola Online iOS User",
    review:
      "Applicazione davvero carina, semplice e perfetta per passare il tempo sia in compagnia che da soli!",
  },
  {
    id: 2,
    avatar: "images/avatar-1.svg",
    userName: "area.queen",
    subtitle: "Tombola Online iOS User",
    review: "Ottima app per giocare insieme agli amici a tombola",
  },
  {
    id: 3,
    avatar: "images/avatar-1.svg",
    userName: "pasqualenapoli1234",
    subtitle: "Tombola Online iOS User",
    review:
      "Bella applicazione per giocare in famiglia a tombola, anche con gli amici",
  },
  {
    id: 4,
    avatar: "images/avatar-1.svg",
    userName: "pasqualenapoli1234",
    subtitle: "Tombola Online iOS User",
    review:
      "Bella applicazione per giocare in famiglia a tombola, anche con gli amici",
  },
  {
    id: 5,
    avatar: "images/avatar-1.svg",
    userName: "flyandra",
    subtitle: "Calcolapizza iOS User",
    review:
      "Perfect for calculating the doses for Neapolitan pizza. You enter the data and it calculates everything on the fly.",
  },
  {
    id: 6,
    avatar: "images/avatar-2.svg",
    userName: "Cupido2",
    subtitle: "Calcolapizza iOS User",
    review:
      "Simple, beautiful and very useful app that calculates the doses of all the ingredients to have the perfect pizza according to our tastes!",
  },
  {
    id: 7,
    avatar: "images/avatar-3.svg",
    userName: "Domenico RD",
    subtitle: "Calcolapizza Android User",
    review: "Super application :)",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="Reviews" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
