import React from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header3 from "../components/layouts/Header3";
import Footer from "../components/layouts/Footer";
import About from "../components/sections/About";
import Contact from "../components/sections/Contact";
import Herosection from "../components/sections/Herosection";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";

function Homepage() {
  document.body.classList.add("dark");
  return (
    <>
      <Header3 logoSource="/images/logo.png" />
      <main className="content-3">
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-works">
          <Works />
        </Element>
        <Element name="section-testimonial">
          <Testimonials />
        </Element>
        <Element name="section-contact">
          <Contact />
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
      <Footer />
    </>
  );
}

export default Homepage;
